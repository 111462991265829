import { graphqlQuery } from "../../api/apollo.setup";
import {
  ADD_TO_CONSULTED,
  GET_CONSULTED_OFFERS,
  GET_OFFERS,
} from "../../api/graphql/offer";
import {
  AddToConsultedResponse,
  ConsultedRequest,
  GetConsultedOffersResponse,
  GetOffersRequest,
  GetOffersResponse,
  Offer,
  OfferFilters,
} from "../../types/offer.type";
import { getOfferImage } from "../../utils/functions.utils";
import { Dispatch } from "../store";
import { OffersActions } from "./offers.slice";

export const getOffers =
  (
    filters: OfferFilters,
    currentPage?: number,
    itemsPerPage?: number,
    companySlug?: string
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(OffersActions.setLoading(true));
      const res = await graphqlQuery<GetOffersResponse, GetOffersRequest>(
        GET_OFFERS,
        {
          GetOffersInput: {
            currentPage,
            itemsPerPage,
            filters: {
              ...filters,
              companySlug,
            },
          },
        }
      );
      if (res.data)
        dispatch(
          OffersActions.fetchOffersData({
            ...res.data.getOffers,
            data: res.data.getOffers.data.map((offer) => ({
              ...offer,
              imageUrl: getOfferImage(offer),
            })),
          })
        );
    } catch (error) {
      dispatch(OffersActions.fetchOffersFails(error));
    }
  };

export const getConsultedOffers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(OffersActions.fetchConsultedOffersRequest());
    const res = await graphqlQuery<GetConsultedOffersResponse, never>(
      GET_CONSULTED_OFFERS
    );
    dispatch(OffersActions.fetchConsultedOffers(res.data.getConsultedOffers));
  } catch (error) {
    dispatch(OffersActions.fetchOffersFails(error));
  }
};

export const addToConsulted = (offer: Offer) => async (dispatch: Dispatch) => {
  try {
    dispatch(OffersActions.fetchConsultedOffersRequest());
    await graphqlQuery<AddToConsultedResponse, ConsultedRequest>(
      ADD_TO_CONSULTED,
      {
        offerId: offer.id,
      }
    );
  } catch (error) {
    dispatch(OffersActions.fetchOffersFails(error));
  }
};
