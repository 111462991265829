import gql from "../../apollo.setup";

export const GET_OFFERS = gql`
  query GetOffers($GetOffersInput: GetOffersInput!) {
    getOffers(GetOffersInput: $GetOffersInput) {
      data {
        id
        slug
        createdAt
        updatedAt
        publishedAt
        title
        contractTypes
        startingDate
        minSalary
        maxSalary
        salaryRate
        requiredDegrees
        companyName
        companyLogo
        jobExternalLink
        isSponsored
        company {
          name
          logoPath
          slug
          coverImagePath
          privileges
        }
        localization {
          id
          country
          city
        }
        score
      }
      currentPage
      total
    }
  }
`;

export const GET_ALL_OFFER_SLUGS = gql`
  query {
    getAllOffers {
      slug
      createdAt
    }
  }
`;

export const GET_ALL_POSITIONS_SLUGS = gql`
  query {
    getAllPositions {
      slug
      createdAt
    }
  }
`;

export const GET_MATCHED_OFFERS = gql`
  query GetMostSuitableOffers($GetOffersInput: GetOffersInput!) {
    getMostSuitableOffers(GetOffersInput: $GetOffersInput)
  }
`;

export const GET_ONE_OFFER = gql`
  query GetSingleOffer($GetOneOfferInput: GetOneOfferInput!) {
    getOneOffer(GetOneOfferInput: $GetOneOfferInput) {
      id
      slug
      createdAt
      updatedAt
      publishedAt
      reference
      title
      contractTypes
      description
      profileDescription
      advantages
      startingDate
      yearsOfExperience
      requiredDegrees
      minSalary
      maxSalary
      salaryRate
      isFavorite
      jobTime
      status
      companyName
      companyLogo
      jobExternalLink
      isSponsored
      positions {
        id
        name
      }

      languages {
        id
        name
      }
      company {
        slug
        name
        presentation
        coverImagePath
        privileges
        pictures {
          path
        }
        logoPath

        grouping {
          id
          name
          picture
          promoCode
          companyCode
          percentage
        }

        mainGrouping {
          id
          name
          picture
          promoCode
          companyCode
          percentage
        }
      }
      localization {
        id
        country
        city
        longitude
        latitude
        distance
      }
      similarOffers {
        id
        slug
        createdAt
        updatedAt
        publishedAt
        title
        contractTypes
        startingDate
        minSalary
        maxSalary
        salaryRate
        requiredDegrees
        company {
          slug
          name
          logoPath
          coverImagePath
        }
        localization {
          id
          country
          city
        }
      }
    }
  }
`;

export const GET_ONE_OFFER_BY_ID = gql`
  query GetOneOfferById($GetUniqueInput: GetUniqueInput!) {
    getOneOfferById(GetUniqueInput: $GetUniqueInput) {
      id
      slug
      createdAt
      updatedAt
      publishedAt
      reference
      title
      contractTypes
      description
      profileDescription
      advantages
      startingDate
      yearsOfExperience
      requiredDegrees
      minSalary
      maxSalary
      salaryRate
      isFavorite
      jobTime
      status
      positions {
        id
        name
      }

      languages {
        id
        name
      }
      company {
        name
        presentation
        coverImagePath
        pictures {
          path
        }
        logoPath
      }
      localization {
        id
        country
        city
        longitude
        latitude
        distance
      }
      similarOffers {
        id
        slug
        createdAt
        updatedAt
        publishedAt
        title
        contractTypes
        startingDate
        minSalary
        maxSalary
        salaryRate
        requiredDegrees
        company {
          name
          logoPath
          coverImagePath
        }
        localization {
          id
          country
          city
        }
      }
    }
  }
`;

export const GET_OFFER_NAME = gql`
  query GetOneOfferById($GetUniqueInput: GetUniqueInput!) {
    getOneOfferById(GetUniqueInput: $GetUniqueInput) {
      id
      title
    }
  }
`;

export const GET_OFFER_ATTRIBUTES = gql`
  query GetOneOfferById($GetUniqueInput: GetUniqueInput!) {
    getOneOfferById(GetUniqueInput: $GetUniqueInput) {
      id
      slug
      reference
      title
      contractTypes
      description
      profileDescription
      advantages
      startingDate
      yearsOfExperience
      requiredDegrees
      minSalary
      maxSalary
      jobTime
      status
      positions {
        id
        name
      }
      languages {
        id
        name
      }
      localization {
        id
        country
        city
        longitude
        latitude
        distance
      }
      jobSlot {
        id
        isActive
      }
      hasJobSlot
    }
  }
`;

export const GET_IS_FAVORITE_OFFER = gql`
  query GetSingleOffer($GetOneOfferInput: GetOneOfferInput!) {
    getOneOffer(GetOneOfferInput: $GetOneOfferInput) {
      id
      isFavorite
    }
  }
`;

export const CAN_APPLY_OFFER = gql`
  query GetSingleOffer($GetOneOfferInput: GetOneOfferInput!) {
    getOneOffer(GetOneOfferInput: $GetOneOfferInput) {
      id
      canApply
    }
  }
`;

export const RESET_NEW_NUMBER_OF_APPLICATIONS = gql`
  mutation ResetNewNumberOfApplications($GetUniqueInput: GetUniqueInput!) {
    resetNewNumberOfApplications(GetUniqueInput: $GetUniqueInput) {
      id
    }
  }
`;

export const ADD_TO_FAVORITES = gql`
  mutation AddToFavorites($offerId: Int!) {
    addToFavorites(OfferFavoriteInput: $offerId) {
      success
    }
  }
`;

export const REMOVE_FROM_FAVORITES = gql`
  mutation RemoveFromFavorites($offerId: Int!) {
    removeFromFavorites(OfferFavoriteInput: $offerId) {
      success
    }
  }
`;

export const ADD_TO_CONSULTED = gql`
  mutation AddToConsulted($offerId: Int!) {
    addToConsulted(OfferConsultedInput: $offerId) {
      success
    }
  }
`;

export const GET_FAVORITES_OFFERS = gql`
  query GetFavoriteOffers($CursorInput: CursorInput!) {
    getFavoriteOffers(CursorInput: $CursorInput) {
      data {
        id
        slug
        createdAt
        updatedAt
        publishedAt
        title
        contractTypes
        startingDate
        minSalary
        maxSalary
        company {
          name
          logoPath
        }
        localization {
          id
          country
          city
        }
      }
      cursor
      total
      fetchMore
    }
  }
`;

export const GET_CONSULTED_OFFERS = gql`
  query {
    getConsultedOffers {
      id
      slug
      createdAt
      updatedAt
      publishedAt
      title
      contractTypes
      startingDate
      minSalary
      maxSalary
      salaryRate
      company {
        name
        logoPath
        coverImagePath
      }
      localization {
        id
        country
        city
      }
    }
  }
`;

export const CREATE_OFFER = gql`
  mutation CreateOffer($CreateOfferInput: CreateOfferInput!) {
    createOffer(CreateOfferInput: $CreateOfferInput) {
      id
      title
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($UpdateOfferInput: UpdateOfferInput!) {
    updateOffer(UpdateOfferInput: $UpdateOfferInput) {
      id
    }
  }
`;

export const SAVE_OFFER = gql`
  mutation SaveOffer($UpdateOfferInput: UpdateOfferInput!) {
    saveOffer(UpdateOfferInput: $UpdateOfferInput) {
      id
      slug
    }
  }
`;

export const PUBLISH_OFFER = gql`
  mutation PublishOffer($GetUniqueInput: GetUniqueInput!) {
    publishOffer(GetUniqueInput: $GetUniqueInput) {
      id
    }
  }
`;

export const ARCHIVE_OFFER = gql`
  mutation ArchiveOffer($GetUniqueInput: GetUniqueInput!) {
    archiveOffer(GetUniqueInput: $GetUniqueInput) {
      id
    }
  }
`;

export const GET_OFFERS_BY_IDS = gql`
  query GetOffersByIds($ids: [Int!]!) {
    getOffersByIds(ids: $ids) {
      id
      slug
      createdAt
      updatedAt
      publishedAt
      title
      contractTypes
      startingDate
      minSalary
      maxSalary
      requiredDegrees
      company {
        name
        logoPath
        coverImagePath
      }
      localization {
        id
        country
        city
      }
      score
    }
  }
`;

export const SHARE_OFFER = gql`
  mutation ShareOffer($ShareOfferInput: ShareOfferInput!) {
    shareOffer(ShareOfferInput: $ShareOfferInput) {
      success
    }
  }
`;

export const GET_SUGGESTED_OFFERS = gql`
  query GetSuggestedOffers($GetSuggestedOffersInput: GetSuggestedOffersInput!) {
    getSuggestedOffers(GetSuggestedOffersInput: $GetSuggestedOffersInput) {
      id
      title
      localization {
        id
        country
        city
      }
    }
  }
`;

export const ASSIGN_OFFERS = gql`
  mutation AssignOffers($AssignOffersInput: AssignOffersInput!) {
    assignOffers(AssignOffersInput: $AssignOffersInput) {
      success
    }
  }
`;

export const GET_OFFERS_POSITIONS = gql`
  query GetOffersByPosition(
    $GetOffersByPositionInput: GetOffersByPositionInput!
  ) {
    getOffersByPosition(GetOffersByPositionInput: $GetOffersByPositionInput) {
      id
      slug
      createdAt
      updatedAt
      publishedAt
      reference
      title
      contractTypes
      description
      profileDescription
      advantages
      startingDate
      yearsOfExperience
      requiredDegrees
      minSalary
      maxSalary
      isFavorite
      jobTime
      status
      positions {
        id
        name
      }
      languages {
        id
        name
      }
      company {
        slug
        name
        presentation
        coverImagePath
        logoPath
        privileges
        pictures {
          path
        }
      }
    }
  }
`;

export const GET_OFFERS_PATH = gql`
  query GetOffersPath($GetOffersPathInput: GetOffersPathInput!) {
    getOffersPath(GetOffersPathInput: $GetOffersPathInput) {
      path
      title
      city
      contractType
      relatedOffersPaths {
        path
        title
        city
      }
      parentOffersPath {
        path
        title
      }
      seoTitle
      seoDescription
    }
  }
`;

export const GET_ALL_OFFERS_PATHS = gql`
  query {
    getOffersPaths {
      path
      title
    }
  }
`;

export const GET_ALL_DISPLAYED_OFFERS_PATHS = gql`
  query {
    getAllDisplayedOffersPaths {
      path
      title
    }
  }
`;
