import { NextRouter } from "next/router";

import { Company, Sector } from "../types/company.type";
import { Offer, OfferFilters, OfferPath } from "../types/offer.type";
import { ContractType, SortStrategiesKeys } from "../types/select.type";
import { OFFERS_PAGE } from "./pages";
import { slugifyText } from "./slugify.utils";

export interface OfferFiltersParams {
  pages?: string;
  titre?: string;
  localisations?: string;
  contrats?: string;
  entreprise?: string;
  maxSalaire?: string;
  minSalaire?: string;
  secteurs?: string;
  trierPar?: string;
  langues?: string;
  dateDeDebut?: string;
  slugOrPage?: string;
}

export const getOfferFiltersFromQueryParams = (
  queryParams: OfferFiltersParams
): OfferFilters => {
  return {
    ...(queryParams.localisations && {
      cities: queryParams.localisations.split(","),
    }),

    ...(queryParams.contrats && {
      contractTypes: queryParams.contrats.split(",") as ContractType[],
    }),
    ...(queryParams.minSalaire && { minSalary: +queryParams.minSalaire }),
    ...(queryParams.maxSalaire && { maxSalary: +queryParams.maxSalaire }),
    ...(queryParams.titre && { title: queryParams.titre }),

    ...(queryParams.secteurs && { sectors: queryParams.secteurs.split(",") }),
    ...(queryParams.langues && {
      languages: queryParams.langues.split(",").map((l) => ({ name: l })),
    }),
    sort: (queryParams.trierPar ?? "RECENT") as SortStrategiesKeys,
    ...(queryParams.dateDeDebut && {
      startingDate: queryParams.dateDeDebut as unknown as Date,
    }),
  };
};

export const mapFiltersToQueryParams = (filters: OfferFilters) => {
  if (Object.keys(filters).filter((key) => key !== "sort").length === 0)
    return "";
  let queryBuilder = "?";
  if (filters.title) queryBuilder += `titre=${filters.title}&`;
  else queryBuilder += "titre=&";
  if (filters.cities)
    queryBuilder += `localisations=${filters.cities.join(",")}&`;
  else queryBuilder += "localisations=&";
  if (filters.contractTypes)
    queryBuilder += `contrats=${filters.contractTypes.join(",")}&`;
  else queryBuilder += "contrats=&";

  if (filters.minSalary) queryBuilder += `minSalaire=${filters.minSalary}&`;
  else queryBuilder += "minSalaire=&";
  if (filters.maxSalary) queryBuilder += `maxSalaire=${filters.maxSalary}&`;
  else queryBuilder += "maxSalaire=&";
  if (filters.sectors)
    queryBuilder += `secteurs=${filters.sectors
      .map((s) => slugifyText(s))
      .join(",")}&`;
  else queryBuilder += "secteurs=&";

  if (filters.languages && filters.languages.length > 0)
    queryBuilder += `langues=${filters.languages
      .map((l) => l.name)
      .join(",")}&`;
  else queryBuilder += "langues=&";

  if (filters.sort) queryBuilder += `trierPar=${filters.sort}&`;
  else queryBuilder += "trierPar=&";
  if (filters.companySlug) queryBuilder += `entreprise=${filters.companySlug}&`;
  else queryBuilder += "entreprise=&";
  if (filters.startingDate)
    queryBuilder += `dateDeDebut=${new Date(
      filters.startingDate
    ).toISOString()}&`;
  else queryBuilder += "dateDeDebut=";

  return queryBuilder;
};

export const pushFiltersToQueryParams = (
  router: NextRouter,
  filters: OfferFilters,
  basePaginationUrl: string
) => {
  router.push(basePaginationUrl + mapFiltersToQueryParams(filters), undefined, {
    shallow: true,
    scroll: false,
  });
};

export const formatSelectedFilters = (
  data: string[] | string | undefined
): string | undefined => {
  if (!data || (typeof data !== "string" && data.length === 0))
    return undefined;
  if (typeof data === "string") return data;
  const res = data.join(", ");
  return res.length > 20 ? res.slice(0, 20) + "..." : res;
};

export const formatSelectedSectors = (
  sectors: Sector[] | undefined,
  selectedSectors: string[]
) => {
  const res =
    sectors && sectors.length > 0
      ? sectors
          ?.filter((s) =>
            selectedSectors?.find((sector) => sector === slugifyText(s.name))
          )
          ?.map((s) => s.name)
      : [];

  // get the first 20 characters of the string
  return res.join(",")?.length > 20
    ? res.join(",").slice(0, 20) + "..."
    : res.join(",");
};

export const getNoIndexFromQueryParams = (queryParams: OfferFiltersParams) => {
  const queryParamsKeys = Object.keys(
    queryParams
  ) as (keyof OfferFiltersParams)[];
  const l = queryParamsKeys
    .filter(
      (key) => key !== "trierPar" && key !== "pages" && key !== "slugOrPage"
    )
    .filter((key) => !!queryParams[key]);
  return !(
    l.length === 0 ||
    (l.length === 1 && l.includes("titre")) ||
    (l.length === 2 && l.includes("titre") && l.includes("localisations")) ||
    (l.length === 2 &&
      l.includes("titre") &&
      l.includes("contrats") &&
      queryParams.contrats?.split(",").length === 1) ||
    (l.length === 3 &&
      l.includes("titre") &&
      l.includes("contrats") &&
      l.includes("localisations") &&
      queryParams.contrats?.split(",").length === 1)
  );
};

export const getImageFromId = (id = 0) => {
  const i = (id % 10) + 1;
  return `/assets/default/default-${i.toString().padStart(2, "0")}.svg`;
};

export const getCompanyImage = (company?: Company) => {
  return (
    company?.logoPath || company?.coverImagePath || getImageFromId(company?.id)
  );
};

export const getOfferImage = (offer: Offer, reverse = false) => {
  const company = offer.company;
  if (reverse) {
    return (
      company?.coverImagePath ||
      company?.logoPath ||
      offer.companyLogo ||
      getImageFromId(offer.id)
    );
  }
  return (
    company?.logoPath ||
    offer.companyLogo ||
    company?.coverImagePath ||
    getImageFromId(offer.id)
  );
};

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

const treatPath = ({ title, contractType, city }: OfferPath) => {
  if (!title) return "";
  return `${capitalize(title)} ${city ? city : contractType}`;
};

export const getOfferPathBreadcrumb = (
  parent: OfferPath,
  child: OfferPath,
  page?: number
) => {
  if (!parent.title || !child.title) return [];
  const breadcrumbs = [
    { text: "Accueil", href: "/" },
    { text: "Offres", href: OFFERS_PAGE },
  ];
  breadcrumbs.push({
    text: capitalize(parent.title),
    href: `${OFFERS_PAGE}${parent.path}/`,
  });
  const childTitle = treatPath(child);
  breadcrumbs.push({ text: childTitle, href: `${OFFERS_PAGE}${child.path}/` });
  if (page)
    breadcrumbs.push({
      text: `Page ${page}`,
      href: `${OFFERS_PAGE}${child.path}/${page}/`,
    });
  return breadcrumbs.map((elt) => ({
    ...elt,
    href: `${process.env.NEXT_PUBLIC_URL}${elt.href}`,
  }));
};
